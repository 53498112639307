// @flow
import * as React from 'react'
import * as R from 'ramda'
import Masonry from 'react-masonry-css'
import { contains, match } from '../../utils/arrayUtils'
import Header from '../header'
import {
  Card,
  CardContentList,
  CardTitle,
  CardTitleGroup,
  CenteredElement,
  FrameworkCard,
  PrimaryView,
  Subtitle,
  ExamplesText,
  LevelItem,
  BREAKPOINT_TABLET,
  BREAKPOINT_DESKTOP,
  MarkdownContent,
} from '../styles'

type Props = {
  pageData: Object,
  genericData: Object,
  html: Object,
}

type State = {
  level: ?number,
  progress: Object,
  isGeneric: boolean,
  inheritsGeneric: boolean,
}

type CriteriaProps = {
  content: Object,
}

type CriteriaState = {
  isHidden: boolean,
}

const masonryBreakpoints = {
  default: 3,
  [BREAKPOINT_DESKTOP]: 2,
  [BREAKPOINT_TABLET]: 1,
}

class ExampleCriteriaComponent extends React.Component<
  CriteriaProps,
  CriteriaState,
> {
  state = { isHidden: true }

  toggleView = (event: SyntheticUIEvent<>) => {
    event != null ? event.preventDefault() : null
    this.setState({
      isHidden: !this.state.isHidden,
    })
  }

  generateExamples = (content: Object) => {
    let criteria = content.examples.map((val, i) => (
      <li key={i + '-' + Math.random()}>{val}</li>
    ))

    return <ul>{criteria}</ul>
  }

  render() {
    const { content } = this.props
    const { isHidden } = this.state

    return (
      <li>
        <ExamplesText onClick={this.toggleView}>
          {content.criteria}
        </ExamplesText>
        {!isHidden ? this.generateExamples(content) : null}
      </li>
    )
  }
}

export default class LevelledRenderer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const genericDataTitles = props.genericData.topics.map(obj => obj.title)
    const pageDataTitles = props.pageData.topics.map(obj => obj.title)
    const genericDataNames = props.genericData.topics.map(obj => obj.name)
    const pageDataNames = props.pageData.topics.map(obj => obj.name)

    // isGeneric checks to see if the variable names and the "Title" is present on both the generic
    // and page date we receive. This should only flag the generic framework, unless someone redeclares the
    // framework titles and variable names in a new framework (which should not get past review)
    const matchingNames = match(genericDataNames, pageDataNames)
    const matchingTitles = match(genericDataTitles, pageDataTitles)
    const isGeneric = matchingNames && matchingTitles

    // inheritsGeneric checks to see if some of the page data and generic framework variable
    // names are shared which is how our inheritance works), but the titles do not exactly match
    const containingNames = contains(genericDataNames, pageDataNames)
    const inheritsGeneric = containingNames && !matchingTitles

    let progress =
      (typeof window !== 'undefined' && localStorage.getItem('progress')) ||
      '{}' // default
    // Unserialise stored value to object
    progress = JSON.parse(progress)

    this.state = {
      level: null,
      progress: progress,
      isGeneric,
      inheritsGeneric,
    }
  }

  handleClick = (val: ?number) => (event: SyntheticUIEvent<>) => {
    event.preventDefault()
    this.setState({
      level: val,
    })
  }

  changeProgress = (uid: Array<*>) => () => {
    this.setState(prevState => {
      let progress = Object.assign({}, prevState.progress)
      let current = this.getProgress(progress, uid) || 0

      if (!current) {
        progress = this.setProgress(progress, uid, 1)
      } else if (typeof current === 'number' && current >= 3) {
        progress = this.setProgress(progress, uid, 0)
      } else if (typeof current === 'number') {
        progress = this.setProgress(progress, uid, ++current)
      }
      // console.log(progress, uid, current)

      // Safely store for later retrieval
      typeof window !== 'undefined' &&
        localStorage.setItem('progress', JSON.stringify(progress))

      return { progress }
    })

    // console.log(event.target)
  }

  getProgress = (obj: Object, path: Array<*>) => {
    let result = path.filter(s => s).reduce((acc, val) => acc && acc[val], obj)
    // console.log('test', result, path)
    return result || ''
  }

  setProgress = (obj: Object, path: Array<*>, value: number) => {
    let data = obj
    for (let i = 0; i < path.length; i++) {
      let item = path[i]
      if (!data[item]) data[item] = {}
      if (i === path.length - 1) {
        data[item] = value
      } else {
        data = data[item]
      }
      // console.log(data, item)
    }
    // console.log('final', obj)
    return obj
  }

  resetProgress = (activeLevel: ?number) => (event: SyntheticUIEvent<>) => {
    event.preventDefault()
    this.setState(prevState => {
      let progress = Object.assign({}, prevState.progress)
      const path = location.pathname.replace(/[^0-9a-z]/gi, '')
      progress[path][activeLevel] = {}
      // console.log('test', progress, activeLevel)

      // Safely store for later retrieval
      typeof window !== 'undefined' &&
        localStorage.setItem('progress', JSON.stringify(progress))

      return { progress }
    })
  }

  renderPageHeader() {
    const { pageData } = this.props
    const { level } = this.state

    return (
      <Header
        onClickHandler={this.handleClick}
        resetProgress={this.resetProgress}
        pageData={pageData}
        activeLevel={level}
      />
    )
  }

  renderEmptyState() {
    const { pageData, html } = this.props

    if (pageData.homepage === true && html !== '') {
      return (
        <Card>
          <MarkdownContent dangerouslySetInnerHTML={{ __html: html }} />
        </Card>
      )
    } else {
      return (
        <CenteredElement>
          <Card>
            <Subtitle>
              To view a framework, please select a level above.
            </Subtitle>
          </Card>
        </CenteredElement>
      )
    }
  }

  renderFramework() {
    const { pageData } = this.props
    let content = pageData.topics.map(topic => this.createTopic(topic))

    return (
      <Masonry
        breakpointCols={masonryBreakpoints}
        columnClassName="framework-columns"
        className="framework-view"
      >
        {content}
      </Masonry>
    )
  }

  createTopic = (topic: Object) => {
    const { genericData } = this.props
    const { level, isGeneric } = this.state
    const genericTopic = genericData.topics.filter(
      obj => obj.name === topic.name,
    )

    const title =
      genericTopic != null && !R.isEmpty(genericTopic)
        ? genericTopic.map(obj => obj.title)[0]
        : topic.title

    const frameworkCriteria = topic.content
      .filter(objContent => objContent.level === level)
      .map(objContent =>
        objContent.criteria != null
          ? objContent.criteria.map((val, i) => {
              let uid = [
                location.pathname.replace(/[^0-9a-z]/gi, ''),
                objContent.level,
                topic.name.replace(/[^0-9a-z]/gi, ''),
                i + 1,
              ]
              let progressVal = this.getProgress(
                this.state.progress,
                uid,
              ).toString()
              return (
                <LevelItem
                  onClick={this.changeProgress(uid)}
                  progress={progressVal}
                  className={progressVal ? 'progress' + progressVal : null}
                  key={i + '-' + Math.random()}
                >
                  {val}
                </LevelItem>
              )
            })
          : null,
      )

    const exampleCriteria = topic.content
      .filter(objContent => objContent.level === level)
      .map(objContent =>
        objContent.exampleCriteria != null
          ? objContent.exampleCriteria.map((val, i) => (
              <ExampleCriteriaComponent
                content={val}
                key={i + '-' + Math.random()}
              />
            ))
          : null,
      )

    const genericCriteria =
      genericTopic != null && !R.isEmpty(genericTopic)
        ? genericTopic.map(obj =>
            obj.content
              .filter(objContent => objContent.level === level)
              .map(objContent =>
                objContent.criteria.map((val, i) => (
                  <li key={i + '-' + Math.random()}>{val}</li>
                )),
              ),
          )
        : null

    if (
      (genericCriteria != null && !R.isEmpty(genericCriteria)) ||
      (exampleCriteria != null && !R.isEmpty(exampleCriteria)) ||
      (frameworkCriteria != null && !R.isEmpty(frameworkCriteria))
    ) {
      return (
        <FrameworkCard key={topic.name}>
          <CardTitleGroup>
            <CardTitle>{title}</CardTitle>
          </CardTitleGroup>
          <CardContentList>
            {frameworkCriteria != null && !R.isEmpty(frameworkCriteria)
              ? frameworkCriteria
              : null}

            {exampleCriteria != null && !R.isEmpty(exampleCriteria)
              ? exampleCriteria
              : null}

            {!isGeneric &&
            !R.isEmpty(genericCriteria) &&
            genericCriteria != null
              ? genericCriteria
              : null}
          </CardContentList>
        </FrameworkCard>
      )
    }
  }

  render() {
    const { level } = this.state

    return (
      <PrimaryView>
        {this.renderPageHeader()}
        {level != null ? this.renderFramework() : this.renderEmptyState()}
      </PrimaryView>
    )
  }
}
