module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#2991cc","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"progression-framework","short_name":"progression-framework","start_url":"/","background_color":"#6667AB","theme_color":"#6667AB","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1dd0c7880b64332349c172dde14a5272"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
