// @flow
import * as React from 'react'
import { graphql } from 'gatsby'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faGithub } from '@fortawesome/free-brands-svg-icons'
import Layout from '../components/layout'
import {
  Subtitle,
  Title,
  DescriptionView,
  Description,
  DescriptionSubtitle,
  DescriptionText,
  DescriptionTitleGroup,
} from '../components/styles'
import '../css/main.css'

type Props = {
  data: Object,
}

export default class Index extends React.Component<Props> {
  render() {
    return (
      <Layout data={this.props.data}>
        <DescriptionView>
          <DescriptionTitleGroup>
            <Subtitle>Welcome To</Subtitle>
            <Title>Progression at Network-N 🎉</Title>
          </DescriptionTitleGroup>
          <Description>
            <DescriptionText>
              This is where we keep the progression frameworks we use across
              Network N.
            </DescriptionText>
            <DescriptionText>
              This is a single place where all our current and future employees
              can see the skills, knowledge and behaviours that we value here.
            </DescriptionText>
            <DescriptionText>
              Applied skills, knowledge and behaviours are known as
              competencies. We have chosen competencies that both map to our
              core values and that will be essential in our future world
              domination 🚀
            </DescriptionText>
            <DescriptionText>
              Aside from our core competencies we think everyone should be
              striving to demonstrate, you will also have a framework for your
              functional area which outlines the specific knowledge or technical
              skills you need in your profession 🛠
            </DescriptionText>
            <DescriptionText>
              The main aim is to provide clarity on expectations and
              transparency for our employees on how they can progress here. It
              can help in identifying areas of improvement and also help
              managers have better progression conversations with their teams.
              It can also help in our talent processes to guide us in designing
              jobs and identifying the right people for us.
            </DescriptionText>
            <DescriptionText>
              <strong>
                It isn&rsquo;t a step-by-step guide and it isn&rsquo;t
                exhaustive. See this more as a compass🧭
              </strong>
            </DescriptionText>
            <DescriptionText>
              <strong>
                These frameworks will evolve over time and will always be a work
                in progress 🏗
              </strong>
            </DescriptionText>
            <DescriptionText>
              <DescriptionSubtitle>Values ⭐️</DescriptionSubtitle>
              Our values represent us and form the basis of our strategy. They
              also form the basis of our competency framework which sets out
              progression routes, and provide clarity around what applied
              behaviours and skills you need to demonstrate to be successful
              here.
            </DescriptionText>
            <DescriptionText>
              <DescriptionSubtitle>We work fast 🏃‍♀️</DescriptionSubtitle>
              We make prompt and clear decisions, and we take responsibility and
              ownership for the actions we take. We learn new things quickly too
              so we can grow quicker.
            </DescriptionText>
            <DescriptionText>
              <DescriptionSubtitle>
                We&rsquo;re always growing 📈
              </DescriptionSubtitle>
              Gaming and our industry is always changing, so we make sure we
              grow with it. We produce new ideas, approaches, and insights to
              what we do, and work to achieve the organisation&rsquo;s goals as
              we go.
            </DescriptionText>
            <DescriptionText>
              <DescriptionSubtitle>We&rsquo;re accurate ⌚</DescriptionSubtitle>
              We plan and stick to that plan. We like to be as meticulously
              organised as possible, but we remain adaptable to evolving
              situations. We deliver to our customers what we say we are going
              to deliver.
            </DescriptionText>
            <DescriptionText>
              <DescriptionSubtitle>
                We&rsquo;re international 🌎
              </DescriptionSubtitle>
              As we continue to grow, we push borders and boundaries into new
              situations and experiences. Collaborative working is something we
              encourage. Understanding and embracing differences is vital.
            </DescriptionText>
            <DescriptionText>
              <DescriptionSubtitle>
                We&rsquo;re nerdy as hell 🤓
              </DescriptionSubtitle>
              Each member of the business has a wealth of knowledge and
              experience in what they do. We encourage passion and nerdiness in
              all forms, and the sharing of that knowledge, whether that be your
              incredible expertise using code, communication, Asana nerdiness,
              or your unrivalled knowledge of Warhammer.
            </DescriptionText>
          </Description>
        </DescriptionView>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            path
            sidebarTitle
            sidebarGroup
          }
        }
      }
    }
  }
`
